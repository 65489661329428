.container {
  .character {
    margin-top: calc(
      0px -
        (
          (
              (var(--grid-item-size) * var(--character-size-increase)) -
                var(--grid-item-size)
            ) / 2
        )
    );
    margin-left: calc(
      0px -
        (
          (
              (var(--grid-item-size) * var(--character-size-increase)) -
                var(--grid-item-size)
            ) / 2
        )
    );
    width: calc(var(--grid-item-size) * var(--character-size-increase));
    height: calc(var(--grid-item-size) * var(--character-size-increase));
  }
}
