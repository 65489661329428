.score {
  font-size: 1.5rem;
}

.startButton {
  display: inline-block;
  background: #0005;
  padding: 10px 30px;
  border-radius: 10px;
  margin: 20px;
  transition: all 0.2s ease-in-out;
  border: solid 1px #fff5;
  font-size: clamp(16px, 50vw, 24px);
  &:hover {
    background: #0009;
    transform: scale(1.1);
    cursor: pointer;
    border: solid 1px #fff8;
  }
}

.gameStarting {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.gameOverBg {
  height: 100vh;
  width: 100vw;
  background: var(--overlay-color);
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
}

.gameOver {
  border-radius: 5%;
  position: absolute;
  z-index: 999;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & input {
    color: #fff;
    border: solid 1px #fff5;
    background: #0005;
    border-radius: 10px;
    font-family: "Roboto Mono", monospace;
  }

  & input[type="text"] {
    width: clamp(240px, 30vw, 600px);
    margin: 15px auto 0 auto;
    padding: 8px;
  }

  & input[type="submit"] {
    margin: 15px auto 0 auto;
    display: block;
    padding: 8px 24px;
    font-size: clamp(16px, 50vw, 24px);
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}

@media (min-width: 1200px) {
  .gameOver {
    & input[type="text"] {
      padding: 15px;
    }
  }
}

.grid {
  width: calc(var(--grid-cols) * var(--grid-item-size));
  height: calc(var(--grid-rows) * var(--grid-item-size));
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 3px solid #fff5;
  background-color: rgba(0, 0, 0, 0.5);
}

.gridItem {
  background-color: rgba(var(--bg-color), 1);
  outline: 1px solid rgba(255, 255, 255, 0.03);
  width: var(--grid-item-size);
  height: var(--grid-item-size);
  position: relative;
}

.food {
  position: relative;
}

.foodItem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--grid-item-size);
}

.head {
  outline: none;
  z-index: 9;
  & .foodItem {
    display: none;
  }
}

.tail {
  z-index: 8;
  background-color: var(--snake-color);
  & .foodItem {
    display: none;
  }
}

.controls {
  margin-top: 20px;

  & .controlContainer {
    width: 160px;
    height: 70px;
    display: flex;
    justify-content: center;

    & .control {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #fff5;
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
  }

  & .controlRow {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .controls {
    display: none;
  }

  .gameOver input[type="text"] {
    width: clamp(280px, 50vw, 400px);
  }

  .score {
    font-size: 2rem;
    padding: 20px;
  }
}

.title {
  font-size: 4em;
}

.submitMessage {
  position: relative;
}
