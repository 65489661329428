@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

:root {
  --grid-rows: 10;
  --grid-cols: 10;
  --character-size-increase: 2;
  --wobble-right-deg: 3deg;
  --wobble-left-deg: -3deg;
}

.container {
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  font-family: "Roboto Mono", monospace;
}

.leaderBoardFullscreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay {
  background-image: var(--overlay-img);
  filter: opacity(0.3) blur(1px);
  background-position: bottom;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}

:root {
  --grid-item-size: calc(
    min(90vw, 90vh) / max(var(--grid-rows), var(--grid-cols))
  );
}
@media (min-width: 1024px) {
  :root {
    --grid-item-size: calc(
      min(70vw, 70vh) / max(var(--grid-rows), var(--grid-cols))
    );
  }
}
