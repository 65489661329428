.container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Roboto Mono", monospace;
}

.text {
  padding-bottom: 4rem;
}

.characters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.character {
  transition: all 0.2s ease-in-out;
  transform: scale(1);

  &:hover {
    z-index: 99;
    cursor: pointer;
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
  }

  & img {
    margin: 0;
    height: clamp(30px, 30vw, 180px);

    animation-name: wobble;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@media (min-width: 1200px) {
  .characters {
    grid-gap: 5rem;
  }
}

@keyframes wobble {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(var(--wobble-right-deg));
  }
  75% {
    transform: rotate(var(--wobble-left-deg));
  }
}

.button {
  display: inline-block;
  background: #0005;
  color: #fff;
  padding: 18px 24px;
  border-radius: 10px;
  margin: 20px;
  transition: all 0.2s ease-in-out;
  border: solid 1px #fff5;
  font-size: clamp(16px, 50vw, 24px);
  font-family: "Roboto Mono", monospace;

  &:hover {
    background: #0009;
    transform: scale(1.1);
    cursor: pointer;
    border: solid 1px #fff8;
  }
}
