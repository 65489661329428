.leaderboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 1.5em;
  }
  & table {
    width: clamp(280px, 30vw, 600px);
    table-layout: fixed;
  }

  & button {
    display: inline-block;
    background: #0005;
    color: #fff;
    padding: 8px 24px;
    border-radius: 10px;
    margin: 10px 0;
    transition: all 0.2s ease-in-out;
    border: solid 1px #fff5;
    font-size: clamp(16px, 50vw, 24px);
    font-family: "Roboto Mono", monospace;

    &:hover {
      background: #0009;
      transform: scale(1.1);
      cursor: pointer;
      border: solid 1px #fff8;
    }
  }
}
